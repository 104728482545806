import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ReactMarkdown from 'react-markdown';
import CustomLink from '../Link';
import Modal from '../Modal';
import PhoneInputComponent from '../../components/PhoneInput';
import Typeform from '../Typeform';
const Products = (props) => {
  const [showModal, setShowModal] = useState([]);
  const [allModalIndex, setAllModalIndex] = useState([]);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const {
    allProducts,
    productInformation,
    currentPlan,
    productFeatures,
    productComparison,
    allProductsPage,
    currentNodeLocale,
    singleProductID,
  } = props;

  useEffect(() => {
    const modalsStateInit = [];
    const modalIndexes = [];
    productFeatures.map((feature, index) => {
      const currentCategory = productInformation.category + 'FeatureDetails';
      const currentCategoryFeature = feature[currentCategory];
      if (
        feature.inclucedOnProductsTable &&
        currentCategoryFeature.featureIncludedInProduct &&
        currentCategoryFeature.requiresDescription &&
        currentCategoryFeature.description &&
        currentCategoryFeature.descriptionIncludeExtraInformation
      ) {
        modalsStateInit.push(false);
        modalIndexes.push(productInformation.category + index);
      }
    });
    setAllModalIndex(modalIndexes);
    setShowModal(modalsStateInit);
  }, []);

  const modalOpenCloseStatusHandler = (currentModal) => {
    const updateModals = [...showModal];
    updateModals[allModalIndex.indexOf(currentModal)] = !updateModals[allModalIndex.indexOf(currentModal)];
    setShowModal(updateModals);
  };

  const productFeature = (category, feature, index) => {
    const currentCategory = category + 'FeatureDetails';
    const currentCategoryFeature = feature[currentCategory];
    if (
      currentCategoryFeature.featureIncludedInProduct &&
      currentCategoryFeature.requiresDescription &&
      currentCategoryFeature.description
    ) {
      if (!currentCategoryFeature.descriptionIncludeExtraInformation) {
        return (
          <li key={index}>
            <ReactMarkdown
              source={feature.productsFeature.childMarkdownRemark.rawMarkdownBody}
              disallowedTypes={['paragraph']}
              unwrapDisallowed={true}
            />{' '}
            <ReactMarkdown
              source={currentCategoryFeature.description.childMarkdownRemark.rawMarkdownBody}
              disallowedTypes={['paragraph']}
              unwrapDisallowed={true}
            />
          </li>
        );
      } else if (currentCategoryFeature.descriptionIncludeExtraInformation) {
        return (
          <li key={index}>
            <span
              className="pricing__tooltip"
              onClick={() => modalOpenCloseStatusHandler(productInformation.category + index)}
            >
              <ReactMarkdown
                source={feature.productsFeature.childMarkdownRemark.rawMarkdownBody}
                disallowedTypes={['paragraph']}
                unwrapDisallowed={true}
              />{' '}
              <ReactMarkdown
                source={currentCategoryFeature.description.childMarkdownRemark.rawMarkdownBody}
                disallowedTypes={['paragraph']}
                unwrapDisallowed={true}
              />
              <svg
                className="icon icon--sm ml-1 inline-block align-top"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <Modal
              onClose={() => modalOpenCloseStatusHandler(productInformation.category + index)}
              show={showModal[allModalIndex.indexOf(productInformation.category + index)]}
              transparent
            >
              {documentToReactComponents(currentCategoryFeature.extraInformation.json)}
            </Modal>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <ReactMarkdown
              source={feature.productsFeature.childMarkdownRemark.rawMarkdownBody}
              disallowedTypes={['paragraph']}
              unwrapDisallowed={true}
            />
          </li>
        );
      }
    }
  };

  return (
    <div
      key={productInformation.category}
      className={`${
        productComparison && productInformation.productIdentifier === singleProductID
          ? 'card--focused pricing--featured'
          : productInformation.featured && !singleProductID
          ? 'card--focused pricing--featured'
          : productInformation.featured === false && productInformation.featuredBadge && !productComparison
          ? 'card--focused pricing--nohighlight'
          : ''
      }
       ${
         productComparison
           ? 'pricing pricing--compare'
           : allProductsPage
           ? 'card card--allProductsPage pricing'
           : 'card pricing max-w-sm mx-auto mb-md flex flex-col'
       }`}
    >
      <div className="pricing__header ">
        {productInformation.featured ? (
          productComparison ? null : (
            <span className="badge pricing__badge badge--primary">{productInformation.featuredBadge}</span>
          )
        ) : productInformation.featured === false && productInformation.featuredBadge && !productComparison ? (
          <span className="badge pricing__badge badge--secondary">{productInformation.featuredBadge}</span>
        ) : null}
        <div>
          <div className="pricing__title">{documentToReactComponents(productInformation.productName.json)}</div>
        </div>

        <div>
          {/* Pricing Info Above Pricing */}
          {currentPlan === 1
            ? //if there is no discount
              !productInformation.discount
              ? productInformation.savingsMonthlyAbove && (
                  <div
                    className="pricing__info "
                    dangerouslySetInnerHTML={{
                      __html: productInformation.savingsMonthlyAbove.childMarkdownRemark.html,
                    }}
                  />
                )
              : //if there is a discount display the savings for the annual plan
                productInformation.savingsAnnuallyAbove && (
                  <div className="pricing__price flex ">
                    <strike className="pricing__crossed-out mr-0 my-auto mx-auto">
                      <div
                        className={`pricing__old-price ${
                          productComparison ? 'text-sm font-normal ml-auto' : 'text-4xl'
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: productInformation.savingsAnnuallyAbove.childMarkdownRemark.html,
                        }}
                      />
                    </strike>
                    <div
                      className={`ml-1 my-auto mx-auto relative ${
                        productComparison ? 'text-xs font-normal flex mr-auto' : 'text-3xl'
                      }`}
                    >
                      <div>
                        {productInformation.pricing.length < 2
                          ? productInformation.pricing[0]
                          : productInformation.pricing[currentPlan]}
                      </div>
                      <span className="price__time time">{productInformation.perMonth}</span>
                    </div>
                  </div>
                )
            : // Else plan is Monthly
              productInformation.savingsMonthlyAbove && (
                <div
                  className="pricing__info "
                  dangerouslySetInnerHTML={{
                    __html: productInformation.savingsMonthlyAbove.childMarkdownRemark.html,
                  }}
                />
              )}
        </div>
        <div
          className={` ${
            productInformation.pricing[0] === '0' //If the price is 0
              ? 'pricing__price inline-flex'
              : productInformation.discount //If there is a discount
              ? currentPlan === 0 //If we are in the monthly option
                ? currentPlan === 0 && productComparison
                  ? 'pricing__small pricing__small--monthly'
                  : 'pricing__price inline-flex'
                : 'pricing__small'
              : currentPlan === 1 && productComparison //If we are in the comparison matrix
              ? 'hidden'
              : 'pricing__price inline-flex'
          }`}
        >
          {productInformation.pricing.length < 2
            ? productInformation.pricing[0]
            : productInformation.pricing[currentPlan]}
          <span className="price__time text-xs m-auto">{productInformation.perMonth}</span>
        </div>

        <div className="pricing__info ">
          {/* If price is set to Anually */}
          {currentPlan === 1
            ? //if there is no discount
              !productInformation.discount
              ? productInformation.savingsMonthly && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productInformation.savingsMonthly.childMarkdownRemark.html,
                    }}
                  />
                )
              : //if there is a discount display the savings for the annual plan
                productInformation.savingsAnnually && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productInformation.savingsAnnually.childMarkdownRemark.html,
                    }}
                  />
                )
            : // Else plan is Monthly
              productInformation.savingsMonthly && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: productInformation.savingsMonthly.childMarkdownRemark.html,
                  }}
                />
              )}
        </div>
      </div>

      <Modal onClose={() => setShowBuyModal(!showBuyModal)} show={showBuyModal} purchaseModal={true}>
        <div className="text-center mb-lg">
          <ReactMarkdown
            source={allProducts.purchaseProductModal.content.childMarkdownRemark.rawMarkdownBody}
            disallowedTypes={['paragraph']}
            unwrapDisallowed={true}
          />
        </div>
        <div className="text-center">
          <PhoneInputComponent
            phoneInputWithModal={allProducts.purchaseProductModal}
            currentNodeLocale={currentNodeLocale}
          />
        </div>
      </Modal>

      {productComparison ? (
        <></>
      ) : (
        <>
          <div className="pricing__description ">
            <ul>
              {productFeatures.map((feature, index) => {
                if (feature.inclucedOnProductsTable) {
                  return productFeature(productInformation.category, feature, index);
                }
              })}
            </ul>
          </div>
          <div className="pricing__action">
            <CustomLink link={productInformation.link} />
          </div>
        </>
      )}

      {productInformation.pricing.length < 2 ? (
        <div onClick={() => setShowBuyModal(!showBuyModal)} className="pricing__action">
          <CustomLink link={productInformation.purchaseButton} />
        </div>
      ) : productInformation.typeformYearly && productInformation.typeformMonthly ? (
        currentPlan === 1 ? (
          <>
            <Typeform data={productInformation.typeformYearly} pricing />
            {/* <div onClick={() => setShowCheckoutModal(!showCheckoutModal)} className="pricing__action">
              <CustomLink link={productInformation.purchaseButton} />
            </div>
            <Modal
              onClose={() => setShowCheckoutModal(!showCheckoutModal)}
              show={showCheckoutModal}
              purchaseModal={true}
            > */}
              {/* <iframe
                src="https://ext.gaius.services/uca-gs/home?p0=d&p1=3776_3683&p2=7281_2203&p3=2034_5831&pEGaiusId=123456&pEFirstName=Roger&pELastName=Wieder&pECellphoneNumber=0174%209793717&pEEmail=roger@besecured.de&pPLocale=de&pPCase=nS&pPTid=2021_0301&pPPm=1&pPS1=140_485&pPS2=0&pPS3=0&pX=_ext_7191_9958"
                width="100%"
                height="100%"
              ></iframe> */}
              {/* <iframe
                src={
                  'https://ext.gaius.services/uca-gs/home?p0=d&p1=' +
                  `${productInformation.productCheckoutParameters[1].p1}` +
                  '&p2=' +
                  `${productInformation.productCheckoutParameters[1].p2}` +
                  `&p3=` +
                  `${productInformation.productCheckoutParameters[1].p3}` +
                  '&pEGaiusId=&pEFirstName=&pELastName=&pECellphoneNumber=&pEEmail=&pPLocale=&pPCase=nS&pPTid=' +
                  `${productInformation.productCheckoutParameters[1].pPTid}` +
                  ' &pPPm=' +
                  `${productInformation.productCheckoutParameters[1].pPPm}` +
                  '&pPS1=' +
                  `${productInformation.productCheckoutParameters[1].pPs1}` +
                  '&pPS2=' +
                  `${productInformation.productCheckoutParameters[1].pPs2}` +
                  '&pPS3=' +
                  `${productInformation.productCheckoutParameters[1].pPs3}` +
                  '&pX=' +
                  `${productInformation.productCheckoutParameters[1].pX}` +
                  ''
                }
                title="Checkout"
              ></iframe> */}
            {/* </Modal> */}
          </>
        ) : (
          <>
            <div onClick={() => setShowCheckoutModal(!showCheckoutModal)} className="pricing__action">
              <CustomLink link={productInformation.purchaseButton} />
            </div>
            <Modal
              onClose={() => setShowCheckoutModal(!showCheckoutModal)}
              show={showCheckoutModal}
              purchaseModal={true}
            >
              <iframe
                src={
                  'https://ext.gaius.services/uca-gs/home?p0=d&p1=' +
                  `${productInformation.productCheckoutParameters[0].p1}` +
                  '&p2=' +
                  `${productInformation.productCheckoutParameters[0].p2}` +
                  `&p3=` +
                  `${productInformation.productCheckoutParameters[0].p3}` +
                  '&pEGaiusId=&pEFirstName=&pELastName=&pECellphoneNumber=&pEEmail=&pPLocale=&pPCase=nS&pPTid=' +
                  `${productInformation.productCheckoutParameters[0].pPTid}` +
                  ' &pPPm=' +
                  `${productInformation.productCheckoutParameters[0].pPPm}` +
                  '&pPS1=' +
                  `${productInformation.productCheckoutParameters[0].pPs1}` +
                  '&pPS2=' +
                  `${productInformation.productCheckoutParameters[0].pPs2}` +
                  '&pPS3=' +
                  `${productInformation.productCheckoutParameters[0].pPs3}` +
                  '&pX=' +
                  `${productInformation.productCheckoutParameters[0].pX}` +
                  ''
                }
                title="Checkout"
              ></iframe>
            </Modal>
          </>
        )
      ) : productInformation.purchaseButton[currentPlan] ? (
        <div onClick={() => setShowBuyModal(!showBuyModal)} className="pricing__action">
          <CustomLink link={productInformation.purchaseButton} />
        </div>
      ) : (
        <div onClick={() => setShowBuyModal(!showBuyModal)} className="pricing__action">
          <CustomLink link={productInformation.purchaseButton} />
        </div>
      )}

      {allProductsPage ? (
        <></>
      ) : productComparison ? null : (
        <div className="pricing__trial">
          {productInformation.freeTrial && (
            <div
              dangerouslySetInnerHTML={{
                __html: productInformation.freeTrial.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default Products;
