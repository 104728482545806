import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Toggles from '../Toggles';
import Products from './Products';
import ProductsFeaturesComparison from './ProductsFeaturesComparison';

const ProductTabs = (props) => {
  const [currentPlan, setCurrentPlan] = useState(1);
  const {
    productsInfo,
    productCompare,
    featuresHeading,
    currentNodeLocale,
    singleProductIdentifier,
    allProductsPage,
    compareSlug,
  } = props;
  const updatePlan = (e) => {
    e.preventDefault();
    currentPlan === 1 ? setCurrentPlan(0) : setCurrentPlan(1);
  };
  const productsInformation = productsInfo;

  let businessToggle = false;
  if (
    compareSlug === '/produkte-vergleichen/business' ||
    compareSlug === '/en-de/product-comparison/business' ||
    compareSlug === '/business' ||
    compareSlug === '/en/business'
  ) {
    businessToggle = true;
  }

  return (
    <>
      {/* Plan Switcher */}
      {businessToggle ? (
        <Toggles />
      ) : (
        productsInformation.products[0].productIdentifier !== 'GaiusINSURE for Business' && (
          <div className={`pricing--plans mb-md ${productCompare ? 'pr-8' : ''}`}>
            <span onClick={(e) => updatePlan(e)}>
              <div className="form__group form__group--primary">
                <input
                  className="form__input form__input--checkbox"
                  type="checkbox"
                  id="plan"
                  checked={currentPlan === 1 ? true : false}
                  readOnly
                />
                <label className="form__label justify-center" htmlFor="plan">
                  <span className="form__label-text">{productsInformation.pricingPlans[0]}</span>
                  <span className="form__label-checkbox"></span>
                  <span className="form__label-text">{productsInformation.pricingPlans[1]}</span>
                </label>
              </div>
            </span>
          </div>
        )
      )}

      {/* Pricing Tables */}
      {productCompare && businessToggle ? (
        <>
          <div className={`product-comparison__head z-10 ${allProductsPage ? 'top-0' : 'top-xl'}`}>
            <div className=" pricing pricing--compare services">
              <div className="pricing__header ">
                <div>
                  <div className="pricing__title py-1">
                    <ReactMarkdown source={featuresHeading} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
                  </div>
                </div>
              </div>
            </div>
            {productsInformation.products.map((product, index) => {
              return (
                <div className="product-comparison__head-plan" key={index}>
                  <Products
                    allProducts={productsInformation}
                    productInformation={product}
                    currentPlan={currentPlan}
                    productFeatures={productsInformation.productsFeatures}
                    productComparison={productCompare}
                    singleProductID={singleProductIdentifier}
                    allProductsPage={allProductsPage}
                    currentNodeLocale={currentNodeLocale}
                  />
                </div>
              );
            })}
          </div>
          <ProductsFeaturesComparison productsAndFeaturesToCompare={productsInformation} />
        </>
      ) : productCompare && !businessToggle ? (
        <>
          <div className={`product-comparison__head z-10 ${allProductsPage ? 'top-0' : 'top-xl'}`}>
            <div className=" pricing pricing--compare services">
              <div className="pricing__header ">
                <div>
                  <div className="pricing__title py-1">
                    <ReactMarkdown source={featuresHeading} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
                  </div>
                </div>
              </div>
            </div>
            {productsInformation.products.map((product, index) => {
              return (
                <div className="product-comparison__head-plan" key={index}>
                  <Products
                    allProducts={productsInformation}
                    productInformation={product}
                    currentPlan={currentPlan}
                    productFeatures={productsInformation.productsFeatures}
                    productComparison={productCompare}
                    singleProductID={singleProductIdentifier}
                    allProductsPage={allProductsPage}
                    currentNodeLocale={currentNodeLocale}
                  />
                </div>
              );
            })}
          </div>
          <ProductsFeaturesComparison productsAndFeaturesToCompare={productsInformation} />

          <div className={`pricing--plans mt-lg  ${productCompare ? 'pr-8' : ''}`}>
            <span onClick={(e) => updatePlan(e)}>
              <div className="form__group form__group--primary">
                <input
                  className="form__input form__input--checkbox"
                  type="checkbox"
                  id="plan"
                  checked={currentPlan === 1 ? true : false}
                  readOnly
                />
                <label className="form__label justify-center" htmlFor="plan">
                  <span className="form__label-text">{productsInformation.pricingPlans[0]}</span>
                  <span className="form__label-checkbox"></span>
                  <span className="form__label-text">{productsInformation.pricingPlans[1]}</span>
                </label>
              </div>
            </span>
          </div>
        </>
      ) : (
        <div
          className={
            productsInformation.products.length > 1
              ? `grid gap-md grid-cols-1 xl:grid-cols-4`
              : `grid gap-md grid-cols-1 xl:grid-cols-1`
          }
        >
          {productsInformation.products.map((product) => {
            return (
              <Products
                allProducts={productsInformation}
                productInformation={product}
                currentPlan={currentPlan}
                productFeatures={productsInformation.productsFeatures}
                productComparison={productCompare}
                allProductsPage={allProductsPage}
                currentNodeLocale={currentNodeLocale}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ProductTabs;
