import React from 'react';

class Toggles extends React.Component {
  state = {
    employees: [{ name: '1' }, { name: '2-9' }, { name: '10-25' }, { name: '26-50' }],
    years: [{ name: '1 Year' }, { name: '3 Years' }, { name: '5 Years' }],
    activeEmployees: 1,
    activeYears: 0,
  };

  render() {
    const { employees, years, activeEmployees, activeYears } = this.state;

    return (
      <div className="business-toggle mb-base">
        <h4>Wie viele Mitarbeiter hat dein Unternehmen?</h4>
        <div className="business-toggle--buttons mb-md">
          {employees.map((u, index) => (
            <div
              className={`business-toggle__employees button button--primary button--outline ${
                index === activeEmployees ? 'active' : ''
              }`}
              onClick={() => this.setState({ activeEmployees: index })}
              key={u.name}
            >
              {u.name}
            </div>
          ))}
        </div>
        <h4>Wähle die Laufzeit deines Vertrages</h4>
        <div className="business-toggle--buttons">
          {years.map((u, index) => (
            <div
              className={`business-toggle__years button button--primary button--outline ${
                index === activeYears ? 'active' : ''
              }`}
              onClick={() => this.setState({ activeYears: index })}
              key={u.name}
            >
              {u.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Toggles;
