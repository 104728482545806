import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

const ProductsFeaturesComparison = (props) => {
  const [showModal, setShowModal] = useState([]);
  const { productsAndFeaturesToCompare } = props;

  useEffect(() => {
    var initModals = new Array(props.productsAndFeaturesToCompare.productsFeatures.length).fill(false);
    setShowModal(initModals);
  }, []);

  const showHideModal = (index) => {
    let modalsUpdate = [...showModal];
    modalsUpdate.splice(index, 1, !modalsUpdate[index]);
    setShowModal(modalsUpdate);
  };

  const productFeatures = (products, feature, parentIndex) => {
    return products.map((product, index) => {
      const currentCategory = product.category + 'FeatureDetails';
      const currentCategoryFeature = feature[currentCategory];
      return (
        <div className="product-comparison__details" key={index}>
          {!currentCategoryFeature.description ? (
            currentCategoryFeature.featureIncludedInProduct ? (
              <>
                <svg
                  className="icon icon--sm icon--primary"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              </>
            ) : (
              <svg
                className="icon icon--sm icon--primary"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"></path>
              </svg>
            )
          ) : currentCategoryFeature.mobileDescription ? (
            <>
              <div className="hidden lg:block">
                {currentCategoryFeature.description.childMarkdownRemark.rawMarkdownBody}
              </div>
              <div className="lg:hidden">
                {currentCategoryFeature.mobileDescription.childMarkdownRemark.rawMarkdownBody}
              </div>
            </>
          ) : (
            <div className="">{currentCategoryFeature.description.childMarkdownRemark.rawMarkdownBody}</div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="product-comparison__body">
      {showModal.length > 0 &&
        productsAndFeaturesToCompare.productsFeatures.map((features, index) => {
          return (
            <div className="product-comparison__row" key={index}>
              <div className="product-comparison__title">
                {features.productsFeature && features.productsFeature.childMarkdownRemark.rawMarkdownBody}
                {features.productsFeatureImage && (
                  <img
                    className="w-40"
                    src={features.productsFeatureImage.file.url}
                    key={index}
                    alt={features.productsFeatureImage.description ? features.productsFeatureImage.description : ''}
                  />
                )}
                {features.featureExtraInformation && (
                  <>
                    <span className="pricing__tooltip" onClick={() => showHideModal(index)}>
                      <svg
                        className="icon icon--sm ml-1 inline-block align-top"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                    <Modal onClose={() => showHideModal(index)} show={showModal[index]} transparent>
                      <div className="pricing__extra-info">
                        {documentToReactComponents(features.featureExtraInformation.json)}
                      </div>
                    </Modal>
                  </>
                )}
              </div>

              {productFeatures(productsAndFeaturesToCompare.products, features, index)}
            </div>
          );
        })}
    </div>
  );
};
export default ProductsFeaturesComparison;
